
.main-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}
.main-tab {
    font-size: 16px;
    line-height: 1;
    padding-bottom: 14px;
    border-bottom: 1px solid #F1F5FF;
}
.main-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    /*margin-top: 20px;*/
    .top-item {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        .item-text {
            margin-right: 12px;
        }
        .item + .item {
            margin-left: 20px;
        }
    }
}
.demo-ruleForm {
    .el-form-item {
        margin-bottom: 16px;
    }
    ::v-deep .el-form-item__label {
        font-size: 16px;
        color: #666;
    }
    ::v-deep .el-form-item__error {
        padding-top: 2px;
    }
}
